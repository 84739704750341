import { useState, useEffect } from 'react';

const getWindowDimenstions = () => {
	const { innerWidth, innerHeight } = window;

	return {
		width: innerWidth,
		height: innerHeight,
	};
};

const useWindowDimenstions = () => {
	const [windowDimenstions, setWindowDimenstions] = useState(
		getWindowDimenstions()
	);

	useEffect(() => {
		const handleResize = () => {
			setWindowDimenstions(getWindowDimenstions());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimenstions;
};

export default useWindowDimenstions;

export const GO_HOME = 'GO_HOME';

export const TOGGLE_SCRIPTURE = 'TOGGLE_SCRIPTURE';
export const TOGGLE_TOPIC_SIDEBAR = 'TOGGLE_TOPIC_SIDEBAR';
export const TOGGLE_REFERENCED_SCRIPTURE_PANEL =
	'TOGGLE_REFERENCED_SCRIPTURE_PANEL';
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS';
export const CHANGE_TOPIC_CATEGORY = 'CHANGE_TOPIC_CATEGORY';
export const TOGGLE_CHRONOLOGY = 'TOGGLE_CHRONOLOGY';
export const TOGGLE_FRAGMENTS = 'TOGGLE_FRAGMENTS';
export const TOGGLE_ARABIC = 'TOGGLE_ARABIC';

export const LOADING_SCRIPTURE = 'LOADING_SCRIPTURE';
export const LOADED_SCRIPTURE = 'LOADED_SCRIPTURE';
export const LOADING_SCRIPTURE_ARABIC = 'LOADING_SCRIPTURE_ARABIC';
export const LOADED_SCRIPTURE_ARABIC = 'LOADED_SCRIPTURE_ARABIC';
export const SCROLL_SCRIPTURE_VERSE = 'SCROLL_SCRIPTURE_VERSE';

export const LOADING_SCRIPTURES = 'LOADING_SCRIPTURES';
export const LOADED_SCRIPTURES = 'LOADED_SCRIPTURES';

export const LOADING_LANGUAGES = 'LOADING_LANGUAGES';
export const LOADED_LANGUAGES = 'LOADED_LANGUAGES';

export const LOADING_TOPICS = 'LOADING_TOPICS';
export const LOADED_TOPICS = 'LOADED_TOPICS';

export const LOADING_RELATED_TOPICS = 'LOADING_RELATED_TOPICS';
export const LOADED_RELATED_TOPICS = 'LOADED_RELATED_TOPICS';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const DESELECT_ALL_LANGUAGE = 'DESELECT_ALL_LANGUAGE';
export const SELECT_UI_LANGUAGE = 'SELECT_UI_LANGUAGE';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_TOPIC = 'SELECT_TOPIC';
export const SELECT_VERSE = 'SELECT_VERSE';
export const SELECT_INITIAL_ARABIC_TOGGLE_VALUE =
	'SELECT_INITIAL_ARABIC_TOGGLE_VALUE';
export const SELECT_INITIAL_CHRONOLOGY_TOGGLE_VALUE =
	'SELECT_INITIAL_CHRONOLOGY_TOGGLE_VALUE';
export const SELECT_INITIAL_FRAGMENTS_TOGGLE_VALUE =
	'SELECT_INITIAL_FRAGMENTS_TOGGLE_VALUE';
export const SELECT_TEXT_SIZE = 'SELECT_TEXT_SIZE';

export const LOADING_VERSES = 'LOADING_VERSES';
export const LOADED_VERSES = 'LOADED_VERSES';

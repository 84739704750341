import { SimpleHeader } from '@zindagi-frontend/common/components/header/header';

const Fallback = () => {
	return (
		<>
			<SimpleHeader />
			<div
				className="content-container flex flex-col justify-center items-center "
				style={{ backgroundImage: 'url(/bg.png)' }}
			>
				<div className="px-8 py-4 mb-40 mx-4 bg-white rounded-lg shadow-md">
					<h1 className="text-xl text-center">
						An error occurred. The issue has been reported. Press the home
						button above to continue.
					</h1>
				</div>
			</div>
		</>
	);
};

export default Fallback;

import React, { useEffect } from 'react';
import { useStoreContext } from '@zindagi-frontend/common/state/store';
import { getTopics } from '@zindagi-frontend/common/state/requests/get-topics';
import { getScriptures } from '@zindagi-frontend/common/state/requests/get-scriptures';
import { apiURL } from '../../config';
import Header from '@zindagi-frontend/common/components/header/header';
import { selectCategoryMatchUrl } from '@zindagi-frontend/common/state/actions';
import { useHistory, useParams } from 'react-router';
import { convertToURI } from '@zindagi-frontend/common/utils/urlify';
import { LoadingIndicator } from '@zindagi-frontend/common/components/loading-bar/loading';
import LogoMark from '../../assets/icons/logomark.png';
import { sortTopics } from '@zindagi-frontend/common/utils/sort';
import { Settings } from '@zindagi-frontend/common/components/settings/settings';
import Headtag from '@zindagi-frontend/common/components/helmet/head-tag';

const CategoryView = () => {
	const { state, dispatch } = useStoreContext();
	const history = useHistory();
	const { categoryName } = useParams();

	useEffect(() => {
		window.gtag('config', 'G-12BHHN77T2', {
			page_title: `Category: ${categoryName}`,
			page_path: `/${convertToURI(categoryName)}`,
		});
	}, [categoryName]);

	useEffect(() => {
		getScriptures(apiURL, dispatch, 'Bukhari-Hadith', 'English', 'Bukhari');
		getTopics(apiURL, dispatch, '929ee644-7fdb-4832-a4b6-3948dd1a53ac');
	}, [dispatch]);

	useEffect(() => {
		if (!state.selectedCategory && state.categoryIDs.length) {
			selectCategoryMatchUrl(dispatch, categoryName, state, history);
		}
	}, [state.categoryIDs, dispatch, categoryName, state, history]);

	return (
		<div
			className="bg-repeat-x bg-q-verse-pane w-full h-full min-h-screen"
			style={{ backgroundImage: 'url(/bg.png)' }}
		>
			<Headtag
				title="The Hadith Says"
				description="The text of the Hadith by topic, without interpretation or analysis."
			/>
			<Header menuItems={false} logo={'/logo512.png'} />
			<div className="h-full w-full flex flex-col items-center justify-center px-4 sm:px-8">
				<div className="h-full w-full max-w-6xl mx-4 sm:mx-auto">
					{state.categoriesDict.length ? (
						<LoadingIndicator />
					) : (
						<>
							<h1 className="text-4xl mb-8 mt-12 font-semibold text-q-gray text-center">
								{' '}
								{state.selectedCategory}{' '}
							</h1>
							<div className="bg-white rounded-xl py-8 shadow-lg flex flex-col justify-center items-center">
								{state.categoriesDict[state.selectedCategory] &&
									sortTopics(
										state.topicDict,
										state.categoriesDict[state.selectedCategory]
									).map((id) => {
										return (
											<a
												key={id}
												className="text-q-gray hover:text-q-primary"
												href={`/islam/${convertToURI(
													categoryName
												)}/${convertToURI(state.topicDict[id].name)}`}
											>
												{state.topicDict[id].name}
											</a>
										);
									})}
								<img
									src={LogoMark}
									height={20}
									width={40}
									className="mt-4 mb-4"
									alt="Logo"
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<Settings />
		</div>
	);
};

export default CategoryView;

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	selectLanguage,
	selectTextSize,
	selectUiLanguage,
	toggleSettings,
} from '@zindagi-frontend/common/state/actions';
import { useStoreContext } from '@zindagi-frontend/common/state/store';
import { convertFromNameToCountry } from '../../utils/language-names';
import { storeInCache } from '../../local-storage/use-cache';

export const Settings = () => {
	const { state, dispatch } = useStoreContext();

	return (
		<div
			className={
				'fixed pt-7 px-6 sm:px-10 sm:pr-8 bg-white top-0 h-screen to-transparent drawer-width max-w-md sm:w-1/3 border-r transform transition-all duration-300 ease-in-out z-40 ' +
				(state.showSettings
					? 'shadow-2xl right-0'
					: 'drawer-position sm:-right-1/3')
			}
		>
			<svg
				aria-hidden="true"
				data-prefix="fas"
				data-icon="times"
				className={
					'svg-inline--fa fa-times fa-w-11 absolute h-6 w-6 top-9 sm:top-7 cursor-pointer transform transition-all ease-in-out duration-300 ' +
					(state.showSettings
						? ' right-4 scale-100 opacity-100 rotate-90'
						: ' right-0 scale-25 opacity-0 rotate-0')
				}
				onClick={() => {
					dispatch(toggleSettings());
					window.gtag('event', 'Toggle Settings', {
						event_category: 'Toggle Settings',
					});
				}}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 352 512"
			>
				<path d="M243 256l100-100c12-12 12-32 0-45l-22-22a31 31 0 00-45 0L176 189 76 89a31 31 0 00-45 0L9 111a31 31 0 000 45l100 100L9 356a31 31 0 000 45l22 22c13 12 33 12 45 0l100-100 100 100c12 12 32 12 45 0l22-22c12-13 12-33 0-45L243 256z" />
			</svg>
			<SettingsOptions
				dispatch={dispatch}
				languageIDs={state.languageIDs}
				languageDict={state.languageDict}
				selectedLanguages={state.selectedLanguages}
				textSize={state.textSize}
				loadingVerses={state.loadingVerses}
				uiLanguage={state.uiLanguage}
			/>
		</div>
	);
};

const SettingsOptions = (props) => {
	const {
		dispatch,
		languageIDs,
		languageDict,
		selectedLanguages,
		textSize,
		loadingVerses,
		uiLanguage,
	} = props;

	const languages = getLanguagesForSettings(languageIDs, languageDict);
	const { t, i18n } = useTranslation();
	const languageIDsSorted = languageIDs.sort((id) => {
		return selectedLanguages.includes(id) ? -1 : 1;
	});

	return (
		<div className="h-full flex flex-col">
			<h2 className="text-2xl font-semibold"> {t('buttons.settings')} </h2>
			<h4 className="text-lg font-semibold pt-2">
				{t('labels.language-preference')}
			</h4>
			<div className="flex flex-row flex-wrap space-y-2 pt-2 pb-4">
				<select
					onChange={(e) => {
						dispatch(selectUiLanguage(e.target.value));
						i18n.changeLanguage(convertFromNameToCountry(e.target.value));
						storeInCache('ui-language', e.target.value);
						window.gtag('event', 'Changed UI Language', {
							event_category: 'UI Language',
							event_label: e.target.value,
						});
					}}
					value={uiLanguage}
					className="w-full"
					name="languages"
					id="languages"
				>
					{languages.map((language) => {
						return (
							<option key={language} value={language}>
								{language}
							</option>
						);
					})}
				</select>
			</div>

			<hr />
			<h4 className="text-lg font-semibold my-2"> {t('labels.text-size')} </h4>
			<div className="flex flex-row justify-between items-center pt-2 pb-4">
				<div className="flex flex-row justify-center items-center">
					<input
						type="radio"
						id="small"
						name="textSize"
						value="small"
						checked={'small' === textSize}
						onChange={(e) => {
							dispatch(selectTextSize(e.target.value));
							window.gtag('event', 'Changed text size', {
								event_category: 'Text Size',
								event_label: 'Small',
							});
						}}
					/>
					<label className="ml-2" htmlFor="small">
						{t('labels.small')}
					</label>
				</div>
				<div className="flex flex-row justify-center items-center">
					<input
						type="radio"
						id="medium"
						name="textSize"
						value="medium"
						checked={'medium' === textSize}
						onChange={(e) => {
							dispatch(selectTextSize(e.target.value));
							window.gtag('event', 'Changed text size', {
								event_category: 'Text Size',
								event_label: 'Medium',
							});
						}}
					/>
					<label className="ml-2" htmlFor="medium">
						{t('labels.medium')}
					</label>
				</div>
				<div className="flex flex-row justify-center items-center">
					<input
						type="radio"
						id="large"
						name="textSize"
						value="large"
						checked={'large' === textSize}
						onChange={(e) => {
							dispatch(selectTextSize(e.target.value));
							window.gtag('event', 'Changed text size', {
								event_category: 'Text Size',
								event_label: 'Large',
							});
						}}
					/>
					<label className="ml-2" htmlFor="large">
						{t('labels.large')}
					</label>
				</div>
			</div>
			<hr />
			<h4 className="text-lg font-semibold my-2">{t('labels.translations')}</h4>
			<select
				onChange={(e) => {
					if (loadingVerses) return;
					dispatch(selectLanguage(e.target.value));
					window.gtag('event', 'Changed translation language', {
						event_category: 'Language Select',
						event_label: `Name:${languageDict[e.target.value].name}, Language:${
							languageDict[e.target.value].language
						}`,
					});
				}}
			>
				{languageIDsSorted.map((id) => {
					return (
						<LanguageOption
							key={id}
							language={languageDict[id]}
							dispatch={dispatch}
						/>
					);
				})}
			</select>
		</div>
	);
};

const LanguageOption = ({ language }) => {
	if (
		!language ||
		typeof language === 'undefined' ||
		language.language === 'Arabic'
	)
		return null;
	return (
		<>
			<option key={language.id} value={language.id} title={'ABSD'}>
				{language.language}
			</option>
			<option disabled>
				By {language.translator}{' '}
				{language.name === 'Yusuf Ali' && ' (Highlights Available)'}
			</option>
		</>
	);
};

const getLanguagesForSettings = (languageIDs, languageDict) => {
	const languages = [];
	languageIDs.forEach((id) => {
		if (!languages.includes(languageDict[id].language)) {
			languages.push(languageDict[id].language);
		}
	});
	return languages.sort();
};

import React, { Suspense, lazy } from 'react';
import { Store } from '@zindagi-frontend/common/state/store';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LoadingIndicator } from '@zindagi-frontend/common/components/loading-bar/loading.js';
import CategoryView from './views/category-views/category-view';
import Fallback from './views/fallback/fallback';

const Footer = lazy(() =>
	import('@zindagi-frontend/common/components/header/footer')
);
const Home = lazy(() => import('./views/home-view/home.js'));
const ReaderView = lazy(() => import('./views/reader-view/reader-view'));
const AboutUs = lazy(() => import('./views/about-us/about-us'));

if (
	process.env.NODE_ENV !== 'production' ||
	window.location.hostname !== 'thehadithsays'
) {
	window['ga-disable-G-C98RC0V7MJ'] = true;
}

function App() {
	const myFallback = <Fallback />;

	return (
		<Store>
			<Sentry.ErrorBoundary fallback={myFallback} showDialog>
				<Router>
					<Suspense fallback={<LoadingIndicator />}>
						<Switch>
							<Route path="/" exact component={Home} />
							<Route
								path="/islam/:categoryName/:topicName"
								component={ReaderView}
							/>
							<Route path="/islam/:categoryName" component={CategoryView} />
							<Route path="/about" component={AboutUs} />
							<Route path="*">
								<p> Error 404 </p>
							</Route>
						</Switch>
						<Footer
							copyrightText={'hadith-copyright-label'}
							aboutText={'hdaith-about-label'}
							googleDocsURL={
								'https://docs.google.com/forms/d/e/1FAIpQLSfh7M7_ZbJSMu0X6XcQNzj6_Syqk1K7vJS4NqT_P7w6ejikeA/viewform?usp=sf_link'
							}
						/>
					</Suspense>
				</Router>
			</Sentry.ErrorBoundary>
		</Store>
	);
}

export default App;

import React from 'react';
import LoadingGifQuran from '../../assets/loading-quran.gif';
import LoadingGifHadith from '../../assets/loading-hadith.gif';

export const LoadingIndicator = ({ spacing = true }) => {
	if (!window.location.href) return null;

	const isQuran =
		window.location.href.includes('thequransays') ||
		window.location.href.includes('zindagi-frontend-test');
	return (
		<div
			className={`w-full flex items-center justify-center ${
				spacing ? 'pt-24' : ''
			}`}
		>
			<img
				src={isQuran ? LoadingGifQuran : LoadingGifHadith}
				height="100%"
				width={50}
				alt="Loading Bar"
			/>
		</div>
	);
};

export const LoadingIndicatorHome = () => {
	if (!window.location.href) return null;

	const isQuran =
		window.location.href.includes('thequransays') ||
		window.location.href.includes('zindagi-frontend-test');

	return (
		<div className="w-full h-screen flex items-start justify-center pt-24">
			<img
				src={isQuran ? LoadingGifQuran : LoadingGifHadith}
				height="100%"
				width={50}
				alt="Loading Bar"
			/>
		</div>
	);
};

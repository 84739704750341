export const convertFromNameToCountry = (name) => {
	switch (name) {
		case 'English':
			return 'en';
		case 'Arabic':
			return 'ar';
		case 'French':
			return 'fr';
		case 'Spanish':
			return 'es';
		case 'Russian':
			return 'ru';
		case 'Malayalam':
			return 'ml';
		case 'Persian':
			return 'fa';
		case 'Indonesian':
			return 'id';
		case 'Italian':
			return 'it';
		case 'Bengali':
			return 'bn';
		case 'Turkish':
			return 'tr';
		case 'Tamil':
			return 'ta';
		case 'German':
			return 'de';
		case 'Hebrew':
			return 'he';
		case 'Dutch':
			return 'nl';
		case 'Malay':
			return 'ms';
		case 'Albanian':
			return 'sq';
		case 'Romanian':
			return 'ro';
		case 'Polish':
			return 'pl';
		case 'Norwegian':
			return 'no';
		case 'Sindhi':
			return 'sd';
		case 'Somali':
			return 'so';
		case 'Chinese':
			return 'zh';
		case 'Hindi':
			return 'hi';
		case 'Pashto':
			return 'ps';
		case 'Urdu':
			return 'ur';
		default:
			return 'en';
	}
};

import axios from 'axios';
import { loadingScriptures, loadedScriptures } from '../actions';

export const getScriptures = async (
	apiUrl,
	dispatch,
	scriptureName,
	languageName,
	languageAuthor
) => {
	dispatch(loadingScriptures());
	const url = apiUrl + '/v1/scriptures';
	try {
		let res = await axios({
			method: 'get',
			url: url,
		});
		dispatch(
			loadedScriptures(res, languageName, languageAuthor, scriptureName)
		);
	} catch (e) {
		console.log(e);
	}
};

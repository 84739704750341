import { storeInCache } from '../local-storage/use-cache';
import * as actions from '@zindagi-frontend/common/state/action-types';
import { normaliseVerses, normaliseTopics } from './normalise';

const Reducer = (state, action) => {
	switch (action.type) {
		case actions.TOGGLE_SCRIPTURE:
			return {
				...state,
				showScripture: !state.showScripture,
				showTopicSideBar: false,
			};
		case actions.TOGGLE_REFERENCED_SCRIPTURE_PANEL: {
			return {
				...state,
				showReferencedScripture: !state.showReferencedScripture,
				showTopicSideBar: false,
				showScripture: false,
				showSettings: false,
			};
		}
		case actions.TOGGLE_TOPIC_SIDEBAR: {
			const closePanels = action.payload.width <= 1024;

			return {
				...state,
				showSettings: false,
				showTopicSideBar: !state.showTopicSideBar,
				showScripture: closePanels ? false : state.showScripture,
				showReferencedScripture: closePanels
					? false
					: state.showReferencedScripture,
			};
		}
		case actions.TOGGLE_SETTINGS: {
			const closePanels = action.payload.width <= 1024;

			return {
				...state,
				showSettings: !state.showSettings,
				showTopicSideBar: false,
				showScripture: closePanels ? false : state.showScripture,
				showReferencedScripture: closePanels
					? false
					: state.showReferencedScripture,
			};
		}

		case actions.GO_HOME:
			return {
				...state,
				selectedCategory: null,
				showScripture: false,
				showTopicSideBar: false,
				showSettings: false,
				showFragments: false,
			};
		case actions.CHANGE_TOPIC_CATEGORY: {
			const { topicID, categoryID } = action.payload;
			return {
				...state,
				showTopicSideBar: false,
				selectedTopic: topicID,
				selectedCategory: categoryID,
				selectedVerse: null,
				sortByChrono: false,
			};
		}

		case actions.SCROLL_SCRIPTURE_VERSE:
			return {
				...state,
				scriptureCurrentVerseInView: action.payload,
			};
		case actions.TOGGLE_CHRONOLOGY:
			storeInCache('show-chronology', !state.sortByChrono);
			return {
				...state,
				sortByChrono: !state.sortByChrono,
			};
		case actions.SELECT_INITIAL_CHRONOLOGY_TOGGLE_VALUE:
			return {
				...state,
				sortByChrono: action.payload,
			};
		case actions.TOGGLE_FRAGMENTS:
			storeInCache('show-fragments', !state.showFragments);
			return {
				...state,
				showFragments: !state.showFragments,
			};
		case actions.SELECT_INITIAL_FRAGMENTS_TOGGLE_VALUE:
			return {
				...state,
				showFragments: action.payload,
			};
		case actions.TOGGLE_ARABIC:
			storeInCache('show-arabic', !state.showArabic);
			storeInCache('show-arabic-popup', false);
			return {
				...state,
				showArabic: !state.showArabic,
			};
		case actions.SELECT_INITIAL_ARABIC_TOGGLE_VALUE:
			return {
				...state,
				showArabic: action.payload,
			};
		case actions.LOADING_SCRIPTURE:
			return {
				...state,
				loadingScripture: true,
			};
		case actions.LOADED_SCRIPTURE:
			return {
				...state,
				loadingScripture: false,
				scriptureContent: [...action.payload],
			};
		case actions.LOADING_SCRIPTURE_ARABIC:
			return {
				...state,
				loadingArabicQuran: true,
			};
		case actions.LOADED_SCRIPTURE_ARABIC: {
			const scriptureArabicContent = action.payload.map((c) => {
				return {
					content: c.content,
					verseOrder: c.verseOrder,
					metadata: c.metadata,
				};
			});
			return {
				...state,
				loadingArabicQuran: false,
				scriptureArabicContent: scriptureArabicContent,
			};
		}
		case actions.LOADING_SCRIPTURES:
			return {
				...state,
				loadingScriptures: true,
			};
		case actions.LOADED_SCRIPTURES: {
			const scripture = action.payload.scriptures.find(
				(s) => s.name === action.payload.scriptureName
			);
			const selectedLanguages = scripture.languages.filter(
				(l) =>
					l.language === action.payload.languageName &&
					l.name === action.payload.languageAuthor
			);
			const arabicLanguage = scripture.languages.find(
				(l) => l.language === 'Arabic'
			);

			const languageDict = {};
			const languageIDs = [];
			scripture.languages.forEach((l) => {
				languageDict[l.id] = l;
				languageIDs.push(l.id);
			});
			languageIDs.sort((id1, id2) => {
				if (languageDict[id1].language > languageDict[id2].language) {
					return 1;
				} else if (languageDict[id1].language < languageDict[id2].language) {
					return -1;
				} else return 0;
			});
			return {
				...state,
				selectedScripture: scripture.id,
				selectedLanguages: selectedLanguages.map((l) => {
					return l.id;
				}),
				// scriptures: action.payload.scriptures,
				languageDict: languageDict,
				languageIDs: languageIDs,
				arabicID: arabicLanguage ? arabicLanguage.id : '',
				loadingScriptures: false,
			};
		}

		case actions.LOADING_TOPICS:
			return {
				...state,
				loadingTopics: true,
			};
		case actions.LOADED_TOPICS: {
			const { topicIDs, topicDict, categoriesDict, categoryIDs } =
				normaliseTopics(action.payload);
			return {
				...state,
				loadingTopics: false,
				topicIDs: topicIDs,
				topicDict: topicDict,
				categoriesDict: categoriesDict,
				categoryIDs: [...categoryIDs],
			};
		}

		case actions.LOADING_RELATED_TOPICS:
			return {
				...state,
				loadingRelatedTopics: true,
			};
		case actions.LOADED_RELATED_TOPICS: {
			const relatedTopicIDs = action.payload
				.sort((a, b) => {
					if (a.name > b.name) return 1;
					if (a.name < b.name) return -1;
					return 0;
				})
				.map((topic) => topic.id);
			return {
				...state,
				loadingRelatedTopics: false,
				relatedTopicIDs: relatedTopicIDs,
			};
		}

		case actions.SELECT_TOPIC:
			return {
				...state,
				selectedTopic: action.payload,
				selectedVerse: null,
				sortByChrono: false,
				showScripture: false,
				showTopicSideBar: false,
			};
		case actions.SELECT_LANGUAGE: {
			return {
				...state,
				selectedLanguages: [action.payload],
				loadingScripture: true,
				loadingVerses: true,
			};
		}
		case actions.DESELECT_ALL_LANGUAGE: {
			const selectedLanguage = state.languageIDs.find((id) => {
				state.languageDict[id].name === action.payload.languageAuthor &&
					state.languageDict[id].language === action.payload.languageName;
			});
			return {
				...state,
				selectedLanguages: [selectedLanguage],
			};
		}
		case actions.SELECT_UI_LANGUAGE: {
			return {
				...state,
				uiLanguage: action.payload,
			};
		}

		case actions.SELECT_CATEGORY:
			return {
				...state,
				selectedCategory: action.payload,
				selectedTopic: null,
				selectedVerse: null,
			};
		case actions.SELECT_VERSE: {
			let shouldQuranToggle = false;
			if (action.payload) shouldQuranToggle = true;
			return {
				...state,
				selectedVerse: action.payload,
				showScripture: shouldQuranToggle,
				showReferencedScripture: false,
				showTopicSideBar: false,
				showSettings: false,
			};
		}

		case actions.SELECT_TEXT_SIZE:
			storeInCache('text-size', action.payload);
			return {
				...state,
				textSize: action.payload,
			};
		case actions.LOADING_VERSES:
			return {
				...state,
				loadingVerses: true,
			};
		case actions.LOADED_VERSES: {
			const { verseGroupIDs, verseGroupDict, verseDict } = normaliseVerses(
				action.payload
			);
			return {
				...state,
				loadingVerses: false,
				verseGroupIDs: verseGroupIDs,
				verseGroupDict: verseGroupDict,
				verseDict: verseDict,
			};
		}

		default:
			return state;
	}
};

export default Reducer;

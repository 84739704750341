import React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
	goHome,
	toggleSettings,
	toggleTopicSidebar,
} from '../../state/actions';
import { SubHeading } from '../heading/sub-heading';
import { useStoreContext } from '@zindagi-frontend/common/state/store';
import { Settings } from '../settings/settings';
import useWindowDimenstions from '../../utils/use-window-dimentions';

const Header = ({ menuItems = true, logo = null, mainSubHeading = '' }) => {
	const location = useLocation();

	if (location.pathname === '/') {
		return <HeaderHomePage mainSubHeading={mainSubHeading} />;
	} else {
		return <HeaderMainPage menuItems={menuItems} logo={logo} />;
	}
};

const HeaderHomePage = ({ mainSubHeading }) => {
	const { dispatch } = useStoreContext();
	const { width } = useWindowDimenstions();

	return (
		<div className="p-4 sm:p-8 sm:pt-8 z-20">
			<div className="flex-1 flex flex-col justify-center items-center">
				<button
					className="fixed top-8 right-8 outline-none focus:outline-none"
					onClick={() => {
						window.gtag('event', 'Close settings panel', {
							event_category: 'Settings',
						});
						dispatch(toggleSettings(width));
					}}
				>
					<svg
						aria-hidden="true"
						data-prefix="fas"
						data-icon="cog"
						className="h-6 w-6 svg-inline--fa fa-cog fa-w-16"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path d="M487 316l-42-25c4-23 4-47 0-70l42-25c5-3 7-8 6-14-11-35-30-68-55-94-4-4-10-5-15-3l-42 25c-18-15-39-27-61-35V26c0-6-4-11-9-12-37-8-75-8-110 0-5 1-9 6-9 12v49c-22 8-43 20-61 35L89 86c-5-3-11-2-15 2-25 26-44 59-55 94-1 6 1 12 6 14l42 25c-4 23-4 47 0 70l-42 25c-5 3-7 8-6 14 11 35 30 68 55 94 4 4 10 5 15 3l42-25c18 15 39 27 61 35v49c0 6 4 11 10 12 36 8 74 8 109 0 5-1 9-6 9-12v-49c22-8 43-20 61-35l43 25c4 2 11 2 14-3 25-26 44-59 55-94 2-6-1-12-6-14zm-231 20a80 80 0 110-160 80 80 0 010 160z" />
					</svg>
				</button>
				<img
					src="/logo512.png"
					height="80"
					width="80"
					alt="Website Logo"
					className="hidden sm:block"
				/>
				<img
					src="/logo512.png"
					height="60"
					width="60"
					alt="Website Logo"
					className="sm:hidden"
				/>
				<SubHeading name={mainSubHeading} className=" pt-4 sm:pt-12 pb-1" />
			</div>
			<Settings />
		</div>
	);
};

const HeaderMainPage = ({ menuItems = true, logo }) => {
	const history = useHistory();
	const { dispatch } = useStoreContext();
	const { width } = useWindowDimenstions();

	return (
		<div className="relative h-20 sm:h-24 bg-white px-4 sm:px-8 flex flex-row flex-shrink-0 items-center justify-between shadow-t-lg z-20">
			<div className="flex-1 flex items-center space-x-4">
				{menuItems && (
					<button
						className="outline-none focus:outline-none"
						onClick={() => {
							window.gtag('event', 'Toggled Category panel', {
								event_category: 'Toggle',
							});
							dispatch(toggleTopicSidebar(width));
						}}
					>
						<svg
							aria-hidden="true"
							data-prefix="fas"
							data-icon="bars"
							className=" h-5 w-5 svg-inline--fa fa-bars fa-w-14"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M16 132h416c9 0 16-7 16-16V76c0-9-7-16-16-16H16C7 60 0 67 0 76v40c0 9 7 16 16 16zm0 160h416c9 0 16-7 16-16v-40c0-9-7-16-16-16H16c-9 0-16 7-16 16v40c0 9 7 16 16 16zm0 160h416c9 0 16-7 16-16v-40c0-9-7-16-16-16H16c-9 0-16 7-16 16v40c0 9 7 16 16 16z" />
						</svg>
					</button>
				)}
			</div>
			<div className="flex-1 flex justify-center items-center">
				<a href={'/'}>
					<img
						src={logo}
						height={40}
						width={40}
						className=" flex-shrink-0 cursor-pointer"
						alt="Quran Says Logo"
					/>
				</a>
			</div>
			<div className="relative flex flex-row justify-end flex-1 space-x-4">
				<button
					className="outline-none focus:outline-none"
					onClick={() => {
						window.gtag('event', 'Close settings panel', {
							event_category: 'Settings',
						});
						dispatch(toggleSettings(width));
					}}
				>
					<svg
						aria-hidden="true"
						data-prefix="fas"
						data-icon="cog"
						className="h-6 w-6 svg-inline--fa fa-cog fa-w-16"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path d="M487 316l-42-25c4-23 4-47 0-70l42-25c5-3 7-8 6-14-11-35-30-68-55-94-4-4-10-5-15-3l-42 25c-18-15-39-27-61-35V26c0-6-4-11-9-12-37-8-75-8-110 0-5 1-9 6-9 12v49c-22 8-43 20-61 35L89 86c-5-3-11-2-15 2-25 26-44 59-55 94-1 6 1 12 6 14l42 25c-4 23-4 47 0 70l-42 25c-5 3-7 8-6 14 11 35 30 68 55 94 4 4 10 5 15 3l42-25c18 15 39 27 61 35v49c0 6 4 11 10 12 36 8 74 8 109 0 5-1 9-6 9-12v-49c22-8 43-20 61-35l43 25c4 2 11 2 14-3 25-26 44-59 55-94 2-6-1-12-6-14zm-231 20a80 80 0 110-160 80 80 0 010 160z" />
					</svg>
				</button>
				<button
					className="outline-none focus:outline-none"
					onClick={() => {
						window.gtag('event', 'Navigate to home page', {
							event_category: 'Navigate',
						});
						dispatch(goHome());
						history.push('/');
					}}
				>
					<svg
						aria-hidden="true"
						data-prefix="fas"
						data-icon="home"
						className=" h-6 w-6 svg-inline--fa fa-bars fa-w-14"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 576 512"
					>
						<path
							fill="currentColor"
							d="M280 148L96 300v164a16 16 0 0016 16h112a16 16 0 0016-16v-96a16 16 0 0116-16h64a16 16 0 0116 16v96a16 16 0 0016 16h112a16 16 0 0016-16V300L296 148a12 12 0 00-16 0zm292 103l-84-68V44a12 12 0 00-12-12h-56a12 12 0 00-12 12v73l-90-74a48 48 0 00-61 0L4 251a12 12 0 00-1 17l25 31a12 12 0 0017 2l235-194a12 12 0 0116 0l235 194a12 12 0 0017-2l25-31a12 12 0 00-1-17z"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export const SimpleHeader = () => {
	const history = useHistory();
	const { dispatch } = useStoreContext();

	return (
		<div className="h-header sm:h-24 px-4 sm:px-8 flex flex-row items-center justify-between shadow-lg z-20">
			<div className="flex-1"></div>
			<div
				className="flex-1 flex justify-center items-center cursor-pointer"
				onClick={() => history.push('/')}
			>
				<img
					src="/logo512.png"
					height={38}
					width={38}
					className="h-24 sm:h-28 object-contain"
					alt="Site Logo"
				/>
			</div>
			<div className="flex-1 text-right">
				<button
					className="outline-none focus:outline-none"
					onClick={() => {
						window.gtag('event', 'Navigate to home page', {
							event_category: 'Navigate',
						});
						dispatch(goHome());
						history.push('/');
					}}
				>
					<svg
						aria-hidden="true"
						data-prefix="fas"
						data-icon="home"
						className=" h-6 w-6 svg-inline--fa fa-bars fa-w-14"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 576 512"
					>
						<path
							fill="currentColor"
							d="M280 148L96 300v164a16 16 0 0016 16h112a16 16 0 0016-16v-96a16 16 0 0116-16h64a16 16 0 0116 16v96a16 16 0 0016 16h112a16 16 0 0016-16V300L296 148a12 12 0 00-16 0zm292 103l-84-68V44a12 12 0 00-12-12h-56a12 12 0 00-12 12v73l-90-74a48 48 0 00-61 0L4 251a12 12 0 00-1 17l25 31a12 12 0 0017 2l235-194a12 12 0 0116 0l235 194a12 12 0 0017-2l25-31a12 12 0 00-1-17z"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default Header;

import React from 'react';
import { Helmet } from 'react-helmet';

const Headtag = ({ title, description }) => {
	return (
		<Helmet>
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
		</Helmet>
	);
};

export default Headtag;

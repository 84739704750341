import { convertToURI } from '../utils/urlify';
import * as actionTypes from './action-types';

//UI Related
export function toggleScripture() {
	return {
		type: actionTypes.TOGGLE_SCRIPTURE,
	};
}

export function toggleTopicSidebar(width) {
	return {
		type: actionTypes.TOGGLE_TOPIC_SIDEBAR,
		payload: { width },
	};
}

export function toggleReferencedScripturePanel() {
	return {
		type: actionTypes.TOGGLE_REFERENCED_SCRIPTURE_PANEL,
	};
}

export function toggleSettings(width) {
	return {
		type: actionTypes.TOGGLE_SETTINGS,
		payload: { width },
	};
}

export function changeTopicCategory(categoryID, topicID) {
	return {
		type: actionTypes.CHANGE_TOPIC_CATEGORY,
		payload: {
			categoryID,
			topicID,
		},
	};
}

export function scrollScriptureVerseInfoTo(verse) {
	return {
		type: actionTypes.SCROLL_SCRIPTURE_VERSE,
		payload: verse,
	};
}

export function goHome() {
	return {
		type: actionTypes.GO_HOME,
	};
}

export function toggleChronologyOrder() {
	return {
		type: actionTypes.TOGGLE_CHRONOLOGY,
	};
}

export function selectInitialChronologyToggleValue(value) {
	return {
		type: actionTypes.SELECT_INITIAL_CHRONOLOGY_TOGGLE_VALUE,
		payload: value,
	};
}

export function toggleFragments() {
	return {
		type: actionTypes.TOGGLE_FRAGMENTS,
	};
}

export function selectInitialFragmentsToggleValue(value) {
	return {
		type: actionTypes.SELECT_INITIAL_FRAGMENTS_TOGGLE_VALUE,
		payload: value,
	};
}

export function toggleArabic() {
	return {
		type: actionTypes.TOGGLE_ARABIC,
	};
}

export function selectInitialArabicToggleValue(value) {
	return {
		type: actionTypes.SELECT_INITIAL_ARABIC_TOGGLE_VALUE,
		payload: value,
	};
}

//Scriptures
export function loadingScriptures() {
	return {
		type: actionTypes.LOADING_SCRIPTURES,
	};
}

export function loadedScriptures(
	res,
	languageName,
	languageAuthor,
	scriptureName
) {
	return {
		type: actionTypes.LOADED_SCRIPTURES,
		payload: {
			scriptures: res.data.data,
			languageName: languageName,
			languageAuthor: languageAuthor,
			scriptureName: scriptureName,
		},
	};
}

// Languages
export function loadinglanguages() {
	return {
		type: actionTypes.LOADING_LANGUAGES,
	};
}

export function loadedLanguages(content) {
	return {
		type: actionTypes.LOADED_LANGUAGES,
		payload: content,
	};
}

//  Loading Scripture
export function loadingScripture() {
	return {
		type: actionTypes.LOADING_SCRIPTURE,
	};
}

export function loadedScripture(res) {
	return {
		type: actionTypes.LOADED_SCRIPTURE,
		payload: res,
	};
}

export function loadingScriptureArabic() {
	return {
		type: actionTypes.LOADING_SCRIPTURE_ARABIC,
	};
}

export function loadedScriptureArabic(res) {
	return {
		type: actionTypes.LOADED_SCRIPTURE_ARABIC,
		payload: res,
	};
}

//Topics
export function loadingTopics() {
	return {
		type: actionTypes.LOADING_TOPICS,
	};
}

export function loadedTopics(res) {
	return {
		type: actionTypes.LOADED_TOPICS,
		payload: res.data.data,
	};
}

export function loadingRelatedTopics() {
	return {
		type: actionTypes.LOADING_RELATED_TOPICS,
	};
}

export function loadedRelatedTopics(res) {
	return {
		type: actionTypes.LOADED_RELATED_TOPICS,
		payload: res.data.data,
	};
}

export function selectTopic(id) {
	return {
		type: actionTypes.SELECT_TOPIC,
		payload: id,
	};
}

export function selectTextSize(size) {
	return {
		type: actionTypes.SELECT_TEXT_SIZE,
		payload: size,
	};
}

export function selectCategoryMatchUrl(dispatch, categoryName, state, history) {
	for (let i = 0; i < state.categoryIDs.length; i++) {
		if (convertToURI(state.categoryIDs[i]) === categoryName) {
			dispatch(selectCategory(state.categoryIDs[i]));
			return;
		}
	}

	history.push('/');
}

export function selectTopicCategoryMatchUrl(
	dispatch,
	topicNameParam,
	categoryName,
	state,
	history
) {
	let found = false;
	for (let i = 0; i < state.categoryIDs.length; i++) {
		if (convertToURI(state.categoryIDs[i]) === categoryName) {
			dispatch(selectCategory(state.categoryIDs[i]));
			found = true;
			break;
		}
	}
	if (!found) history.push('/');
	for (let i = 0; i < state.topicIDs.length; i++) {
		if (
			convertToURI(state.topicDict[state.topicIDs[i]].name) === topicNameParam
		) {
			dispatch(selectTopic(state.topicIDs[i]));
			return;
		}
	}

	history.push('/');
}

export function selectLanguage(id) {
	return {
		type: actionTypes.SELECT_LANGUAGE,
		payload: id,
	};
}

export function deselectAllLanguages(languageName, languageAuthor) {
	return {
		type: actionTypes.DESELECT_ALL_LANGUAGE,
		payload: { languageName, languageAuthor },
	};
}

export function selectUiLanguage(l) {
	return {
		type: actionTypes.SELECT_UI_LANGUAGE,
		payload: l,
	};
}

export function selectCategory(category) {
	return {
		type: actionTypes.SELECT_CATEGORY,
		payload: category,
	};
}

export function selectVerse(verse) {
	return {
		type: actionTypes.SELECT_VERSE,
		payload: verse,
	};
}

//Verses
export function loadingVerses() {
	return {
		type: actionTypes.LOADING_VERSES,
	};
}

export function loadedVerses(res) {
	return {
		type: actionTypes.LOADED_VERSES,
		payload: res.data.data,
	};
}

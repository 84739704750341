import axios from 'axios';
import { loadingTopics, loadedTopics } from '../actions';

export const getTopics = async (apiUrl, dispatch, scriptureId) => {
	dispatch(loadingTopics());

	const params = new URLSearchParams();
	params.append('scriptureId', scriptureId);

	const url = `${apiUrl}/v1/topics`;
	try {
		let res = await axios({
			method: 'get',
			url: url,
			params: params,
		});
		dispatch(loadedTopics(res));
	} catch (e) {
		console.log(e);
	}
};

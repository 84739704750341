export const normaliseVerses = (verseGroups) => {
	const verseGroupIDs = [];
	const verseGroupDict = {};
	const verseDict = {};

	verseGroups.forEach((vg) => {
		verseGroupIDs.push(vg.id);
		verseGroupDict[vg.id] = vg;
		vg.verseGroupMembers.forEach((vgm) => {
			verseDict[vgm.vo.id] = vgm.vo;
		});
	});
	return { verseGroupIDs, verseGroupDict, verseDict };
};

export const normaliseTopics = (topics) => {
	const topicIDs = [];
	const topicDict = {};
	const categoriesDict = {};
	const categoryIDs = [];

	topics.forEach((t) => {
		if (categoriesDict[t.area]) {
			categoriesDict[t.area].push(t.id);
		} else {
			categoriesDict[t.area] = [];
			categoriesDict[t.area].push(t.id);
			categoryIDs.push(t.area);
		}
		topicIDs.push(t.id);
		topicDict[t.id] = t;
	});

	categoryIDs.sort();
	topicIDs.sort((a, b) => {
		if (topicDict[a].name > topicDict[b].name) return 1;
		if (topicDict[a].name < topicDict[b].name) return -1;
		return 0;
	});

	return { topicIDs, topicDict, categoriesDict, categoryIDs };
};

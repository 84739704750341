import React, { createContext, useReducer, useContext } from 'react';
import Reducer from './reducer';

const initialState = {
	showScripture: false,
	showTopicSideBar: false,
	showSettings: false,
	showReferencedScripture: false,
	textSize: 'medium',
	sortByChrono: false,
	showArabic: false,
	showFragments: false,
	scriptureContent: [],
	scriptureArabicContent: [],
	scriptureCurrentVerseInView: '',
	selectedLanguages: [],
	uiLanguage: 'English',
	selectedScripture: '',
	selectedCategory: '',
	selectedTopic: '',
	selectedVerse: '',
	loadingScriptures: false,
	loadingScripture: false,
	loadingArabicQuran: false,
	loadingTopics: false,
	loadingVerses: false,
	loadingRelatedTopics: false,
	arabicID: [],
	languageIDs: [],
	languageDict: [],
	relatedTopicIDs: [],
	topicIDs: [],
	topicDict: [],
	verseGroupIDs: [],
	verseGroupDict: {},
	verseDict: {},
	categoriesDict: {},
	categoryIDs: [],
};

const Context = createContext({ state: initialState, dispatch: null });

const useStoreContext = () => {
	return useContext(Context);
};

const Store = ({ children }) => {
	const [state, dispatch] = useReducer(Reducer, initialState);
	const value = { state, dispatch };
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Store, Context, useStoreContext };

export const storeInCache = (key, value) => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
		return value;
	} catch (e) {
		localStorage.clear();
		console.log(e);
	}
};

export const readFromCache = async (key, defaultValue) => {
	try {
		const value = await JSON.parse(localStorage.getItem(key));
		if (value === '' || value === null || value === undefined || !value) {
			localStorage.setItem(key, JSON.stringify(defaultValue));
			return defaultValue;
		}
		return value;
	} catch (e) {
		console.log(e);
		localStorage.removeItem(key);
	}
};

export const readCachedVersePage = (page) => {
	try {
		const cachedState = JSON.parse(localStorage.getItem('cachedState'));
		if (cachedState.pages[page]) {
			return cachedState.pages[page];
		} else return null;
	} catch (e) {
		console.log(e);
		localStorage.removeItem('cachedState');
	}
};

export const getCachedLanguagesForAPage = (page) => {
	try {
		const cachedState = JSON.parse(localStorage.getItem('cachedState'));
		if (!cachedState) return null;
		if (cachedState.pages[page]) {
			return Object.keys(cachedState.pages[page][0].languages);
		} else return null;
	} catch (e) {
		console.log(e);
		localStorage.removeItem('cachedState');
	}
};

export const cacheVersePage = (languageID, page, pageData) => {
	try {
		const cachedState = JSON.parse(localStorage.getItem('cachedState'));
		if (cachedState) {
			// Cache object exists, add new data to cache
			if (cachedState.pages[page]) {
				//Page exists
				cachedState.pages[page] = cachedState.pages[page].map((row, i) => {
					const newRow = {
						...row,
						languages: {
							...row.languages,
						},
					};
					newRow.languages[languageID] = pageData[i].languages[languageID];
					return newRow;
				});
			} else {
				//Page does not exist
				cachedState.pages[page] = pageData;
			}

			localStorage.setItem('cachedState', JSON.stringify(cachedState));
		} else {
			// Create new cache object
			const cachedState = {
				pages: {},
			};
			cachedState.pages[page] = {};
			cachedState.pages[page] = pageData;
			localStorage.setItem('cachedState', JSON.stringify(cachedState));
		}
	} catch (e) {
		console.log(e);
		localStorage.removeItem('cachedState');
	}
};

export const storeLanguageInCache = (languageID) => {
	localStorage.setItem('language', JSON.stringify(languageID));
};

export const deleteKey = (key) => {
	localStorage.removeItem(key);
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@zindagi-frontend/common/components/i18n/i18n-translation';
import * as ServiceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
	dsn: 'https://4fce406b5e364391aba0634edd5ad4e5@o627068.ingest.sentry.io/5868655',
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.NODE_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	rootElement
);

ServiceWorkerRegistration.register();

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar from '../../assets/translations/ar.json';
import bn from '../../assets/translations/bn.json';
import de from '../../assets/translations/de.json';
import en from '../../assets/translations/en.json';
import es from '../../assets/translations/es.json';
import fa from '../../assets/translations/fa.json';
import fr from '../../assets/translations/fr.json';
import he from '../../assets/translations/he.json';
import hi from '../../assets/translations/hi.json';
import id from '../../assets/translations/id.json';
import it from '../../assets/translations/it.json';
import ml from '../../assets/translations/ml.json';
import ms from '../../assets/translations/ms.json';
import nl from '../../assets/translations/nl.json';
import no from '../../assets/translations/no.json';
import pl from '../../assets/translations/pl.json';
import ps from '../../assets/translations/ps.json';
import ro from '../../assets/translations/ro.json';
import ru from '../../assets/translations/ru.json';
import sd from '../../assets/translations/sd.json';
import so from '../../assets/translations/so.json';
import sq from '../../assets/translations/sq.json';
import ta from '../../assets/translations/ta.json';
import tr from '../../assets/translations/tr.json';
import ur from '../../assets/translations/ur.json';
import zh from '../../assets/translations/zh.json';

const resources = {
	ar: { translation: ar },
	bn: { translation: bn },
	de: { translation: de },
	en: { translation: en },
	es: { translation: es },
	fa: { translation: fa },
	fr: { translation: fr },
	he: { translation: he },
	hi: { translation: hi },
	id: { translation: id },
	it: { translation: it },
	ml: { translation: ml },
	ms: { translation: ms },
	nl: { translation: nl },
	no: { translation: no },
	pl: { translation: pl },
	ps: { translation: ps },
	ro: { translation: ro },
	ru: { translation: ru },
	sd: { translation: sd },
	so: { translation: so },
	sq: { translation: sq },
	ta: { translation: ta },
	tr: { translation: tr },
	ur: { translation: ur },
	zh: { translation: zh },
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		fallbackLng: 'en',
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;

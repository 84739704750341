import React from 'react';

export const SubHeading = ({ name, className }) => {
	if (name) {
		return (
			<h3 className={'text-sm sm:text-lg text-center text-q-gray ' + className}>
				{name}
			</h3>
		);
	}
	return (
		<p className={'text-sm sm:text-lg text-center text-q-gray ' + className}>
			See
			<a
				href="/islam/promises"
				className="text-sm sm:text-lg text-q-primary hover:text-q-primary-dark cursor-pointer"
			>
				{' '}
				what Allah promises you{' '}
			</a>
			in the Quran
		</p>
	);
};
